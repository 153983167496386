import BaseApi from './BaseApi'
import { mimes } from '@/api/mimes'
import lodash from 'lodash'

class DocumentsApi extends BaseApi {
  async getDocuments({
    page = 1,
    companyId = null,
    withDue = false,
    orderBy,
    orderDirection,
    type,
    search,
  }) {
    let url = `/documents`
    const queryParams = {
      type,
      page,
    }
    if (orderBy) {
      queryParams.orderBy = orderBy
      queryParams.orderDirection = orderDirection
    }
    if (companyId) {
      queryParams.company_id = companyId
    }
    if (withDue) {
      queryParams.dueDate = true
    }
    if (search) {
      queryParams.search = search
    }
    const queryString = this.querystring(queryParams)
    const result = await this.get(`${url}?${queryString}`)
    result.data.forEach((el) => {
      el.pdf = createFullPdfPath(el.pdf)
    })
    return result
  }

  async getAuditTrails({ page = 0 }) {
    const result = await this.get(`/uploadAuditTrail?page=${page}`)
    result.data.forEach((el) => {
      el.download_path = createFullPdfPath(el.file_path)
    })
    return result
  }

  async getAuditTrail(id) {
    return this.get(`/uploadAuditTrail/${id}`)
  }

  async getEntry(id) {
    const result = await this.get(`/documents/${id}`)
    result.pdf = createFullPdfPath(result.pdf)
    if (result.masterData && result.masterData.payments) {
      result.masterData.payments.forEach((paymentYear) => {
        paymentYear.payment_lines.sort((a, b) => {
          const dA = new Date(a.created_at)
          const dB = new Date(b.created_at)

          return dA.getTime() - dB.getTime()
        })
      })
    }
    if (lodash.isEmpty(result.fields)) {
      result.fields = {}
    }

    return result
  }

  downloadPDF(path) {
    const url = `${path}?download=true`
    const fileName = `${Date.now()}.pdf`
    return this.createDownloadRequest(url, fileName)
  }

  async keepLocking(id) {
    const result = await this.post(`/documents/${id}/keepLocking`)
    result.pdf = createFullPdfPath(result.pdf)
    return result
  }

  releaseLock(id) {
    return this.post(`/documents/${id}/releaseLock`)
  }

  unarchiveDocument(id) {
    return this.put(`documents/${id}`, { archived: false })
  }

  archiveDocument(id) {
    return this.put(`documents/${id}`, { archived: true })
  }

  setDue(id, dueDate, dueText) {
    return this.put(`documents/${id}`, {
      dueDate,
      dueText,
    })
  }

  removeDue(id) {
    return this.put(`documents/${id}`, {
      dueDate: null,
      dueText: null,
    })
  }

  changeDocumentFields(id, fields) {
    return this.put(`documents/${id}`, { fields })
  }

  deleteDocument(id) {
    return this.delete(`documents/${id}`)
  }

  getStats() {
    return this.get(`/documents/stats`)
  }

  getTextFromRect(queryParams) {
    const queryString = this.querystring(queryParams)
    return this.get(`/extractions/api?${queryString}`)
  }

  assignMasterDataToDocument(id, master_data_id) {
    return this.put(`documents/${id}`, { master_data_id })
  }

  createDownloadRequest(url, fileName, mime = mimes.pdf) {
    const method = 'get'
    return this.downloadAsFile({ url, method }, mime, fileName)
  }
}

function createFullPdfPath(pdf) {
  return `${BaseApi.baseUrl}/documents/getFile?path=${pdf}`
}

export default new DocumentsApi()
